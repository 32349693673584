import React from 'react';
import ReactDOM from 'react-dom';
import 'core-js/es'  
import 'react-app-polyfill/ie9'  
import 'react-app-polyfill/stable'
import 'antd/dist/antd.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ConfigProvider } from 'antd';
import 'moment/locale/zh-cn';
import locale from 'antd/lib/locale/zh_CN';

// global.httpUrl = "https://www.honghukeji.net/web"; www.guosuan.cc/api //正式服
global.httpUrl = "https://www.guosuan.cc/";  //正式服

ReactDOM.render(
	<ConfigProvider locale={locale}>
		<App />
	</ConfigProvider>,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
