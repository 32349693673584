import React from 'react';
import { Carousel } from 'antd';
import { SlideContainer, SlidePage } from 'react-slidepage';
import { Link } from 'react-router-dom';
require('react-slidepage/lib/index.css');


export default class Index extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            menu: [
                { title: '首页', path: '', id: 'page1', page: 1 },
                { title: '服务', path: '', id: 'page2', page: 2 },
                { title: '案例展示', path: '', page: 3, },
                { title: '企业资质', path: '', id: 'page3', page: 4 },
                { title: '合作伙伴', path: '', id: 'page4', page: 5 },
                { title: '联系我们', path: '', id: 'page5', page: 6 },
            ],
            active: 1,
            slideIndex: 0,
            useWheel: false,
            banner: [1],
            caseList: [],
            zzList: [],
            parterList: []
        }
    }
    componentDidMount() {
        // 获取四条案例
        global.Ajax.hhtc_request('api/api/getTopCase', {}).then(res => {
            if (res.code == 1) {
                this.setState({
                    caseList: res.data
                })
            }
        })
        // 获取资质列表
        global.Ajax.hhtc_request('api/api/getHonorList', {}).then(res => {
            if (res.code == 1) {
                this.setState({
                    zzList: res.data
                })
            }
        })
        // 获取合作伙伴
        global.Ajax.hhtc_request('api/api/getPartnerList', {}).then(res => {
            if (res.code == 1) {
                this.setState({
                    parterList: res.data
                })
            }
        })
    }
    onBefore(origin, direction, target) {
        let active = target;
        this.setState({ active })
    }
    changePage = (event) => {
        let Y = event.deltaY;
        let active = this.state.active,
            slideIndex = this.state.slideIndex;
        if (Y > 0) {  //下一张
            if(this.state.banner==1){
                this.slideRef.slideNext();
                return
            }
            // 判断轮播索引
            if (slideIndex < this.state.banner.length - 1) {  //轮播图还未滚完
                this.sceRef.next()
                slideIndex += 1;
            } else {
                this.slideRef.slideNext();
            }
        } else {
            if (active == 1) {
                if (slideIndex > 0) {
                    this.sceRef.prev()
                    slideIndex--;
                }
            } else {
                this.slideRef.slidePrev();
            }
        }
        this.setState({
            slideIndex
        })
    }
    render() {
        return (
            <div>
                 {/* onWheel={this.changePage} */}
                { /* 导航栏 */}
                <div className='navbar'>
                    <img alt='' title='国算智云' src={require('../imgs/logo.png').default} className='logo' />
                    <div className='yincang'> <i class="down"></i></div>
                    <div className='flex'>
                        {this.state.menu.map((item, index) => (
                            <React.Fragment key={String(index)}>
                                {item.path == '' ?
                                    <p className={`item ${this.state.active == item.page ? 'active' : ''}`} onClick={() => {
                                        if (item.page > 0) {
                                            this.slideRef.slideTo(item.page);
                                        }
                                    }}> {item.title}</p>
                                    :
                                    <Link className='item' target='_blank' to={item.path}>{item.title}</Link>
                                }
                            </React.Fragment>
                        ))}
                    </div>
                </div>
                {this.state.active > 1 && <img
                    alt=''
                    title='上一页'
                    src={require('../imgs/prev.png').default}
                    className='prev cbtn'
                    onClick={() => {
                        this.slideRef.slidePrev();
                    }}
                    style={{ bottom: this.state.active == 6 ? 80 : '' }}
                />}
                {this.state.active < 6 && <img
                    alt=''
                    title='下一页'
                    src={require('../imgs/next.png').default}
                    className='next cbtn'
                    onClick={() => {
                        this.slideRef.slideNext();
                    }}
                />}
                <div className='tipss'>
                    <p>鼠标滚动</p>
                    <img alt='' src={require('../imgs/shub.png').default} />
                </div>
                <SlideContainer ref={dom => this.slideRef = dom} after={this.onBefore.bind(this)}>
                    <SlidePage className='page1' id='page1'>
                        <div className={this.state.active == 1 ? 'active' : ''}>
                            <Carousel infinite={false} ref={dom => this.sceRef = dom}>
                                {this.state.banner.map((item, index) => (
                                    <div className={`box ${this.state.slideIndex == 0 ? 'focus' : ''}`}>
                                        <img alt='' src={require('../imgs/banner.png').default} className='pic' />
                                        <p className='p1'>让互联网科技力量</p>
                                        <p className='p2'>引领新时代</p>
                                        <p className='p3'>紧跟技术前沿，保持创新实力定制研发，为你的客户和团队完美设计。</p>
                                    </div>
                                ))}
                            </Carousel>
                        </div>
                    </SlidePage>
                    <SlidePage className={`page2`} id='page2'>
                        <div className={`flwp ${this.state.active == 2 ? 'active' : ''}`}>
                            <div>
                                <img akt='' src={require('../imgs/pic1.png').default} className='pic' />
                                <div className='mask'>
                                    <div className='top'></div>
                                    <div className='bot'></div>
                                    < img alt='' src={require('../imgs/icon1.png').default} className='icon' />
                                    <h1>物联网系统开发</h1>
                                    <p className='line'></p>
                                    <p className='line1'></p>
                                </div>
                                <div className='open'>
                                    <h1>物联网系统开发</h1>
                                    <p className='line2'></p>
                                    <p className='p1'>高效技术开发，成熟系统架构，量体裁衣</p>
                                    <p className='btnn' onClick={() => {
                                        this.slideRef.slideTo(5);
                                    }}>咨询一下</p>
                                </div>
                            </div>
                            <div>
                                <img akt='' src={require('../imgs/pic2.png').default} className='pic' />
                                <div className='mask'>
                                    <div className='top'></div>
                                    <div className='bot'></div>
                                    <img alt='' src={require('../imgs/icon2.png').default} className='icon' />
                                    <h1>APP开发</h1>
                                    <p className='line'></p>
                                    <p className='line1'></p>
                                </div>
                                <div className='open'>
                                    <h1>APP开发</h1>
                                    <p className='line2'></p>
                                    <p className='p1'>根据客户的需求提供高品质的APP开发解决方案</p>
                                    <p className='btnn' onClick={() => {
                                        this.slideRef.slideTo(5);
                                    }}>咨询一下</p>
                                </div>
                            </div>
                            <div>
                                <img akt='' src={require('../imgs/pic.png').default} className='pic' />
                                <div className='mask'>
                                    <div className='top'></div>
                                    <div className='bot'></div>
                                    <img alt='' src={require('../imgs/icon3.png').default} className='icon' />
                                    <h1>综合门户网站</h1>
                                    <p className='line'></p>
                                    <p className='line1'></p>
                                </div>
                                <div className='open'>
                                    <h1>综合门户网站</h1>
                                    <p className='line2'></p>
                                    <p className='p1'>专注于企业品牌化定制设计，形象风格统一</p>
                                    <p className='btnn' onClick={() => {
                                        this.slideRef.slideTo(5);
                                    }}>咨询一下</p>
                                </div>
                            </div>
                            <div>
                                <img akt='' src={require('../imgs/pic4.png').default} className='pic' />
                                <div className='mask'>
                                    <div className='top'></div>
                                    <div className='bot'></div>
                                    <img alt='' src={require('../imgs/icon4.png').default} className='icon' />
                                    <h1>小程序开发</h1>
                                    <p className='line'></p>
                                    <p className='line1'></p>
                                </div>
                                <div className='open'>
                                    <h1>小程序开发</h1>
                                    <p className='line2'></p>
                                    <p className='p1'>微信、支付宝、抖音等小程序功能定制，操作简单易用，高质量用户体验感受</p>
                                    <p className='btnn' onClick={() => {
                                        this.slideRef.slideTo(5);
                                    }}>咨询一下</p>
                                </div>
                            </div>
                            <div>
                                <img akt='' src={require('../imgs/pic5.png').default} className='pic' />
                                <div className='mask'>
                                    <div className='top'></div>
                                    <div className='bot'></div>
                                    <img alt='' src={require('../imgs/icon5.png').default} className='icon' />
                                    <h1>OA管理系统开发</h1>
                                    <p className='line'></p>
                                    <p className='line1'></p>
                                </div>
                                <div className='open'>
                                    <h1>OA管理系统开发</h1>
                                    <p className='line2'></p>
                                    <p className='p1'>提供流程审批、报销、合同、人事、项目、采购等功能，满足各类管理场景</p>
                                    <p className='btnn' onClick={() => {
                                        this.slideRef.slideTo(5);
                                    }}>咨询一下</p>
                                </div>
                            </div>
                            <div>
                                <img akt='' src={require('../imgs/pic6.png').default} className='pic' />
                                <div className='mask'>
                                    <div className='top'></div>
                                    <div className='bot'></div>
                                    <img alt='' src={require('../imgs/icon6.png').default} className='icon' />
                                    <h1>其他开发</h1>
                                    <p className='line'></p>
                                    < p className='line1'></p>
                                </div>
                                <div className='open'>
                                    <h1>其他开发</h1>
                                    <p className='line2'></p>
                                    <p className='p1'>H5开发、微信公众号平台、品牌设计、电商平台等</p>
                                    <p className='btnn' onClick={() => {
                                        this.slideRef.slideTo(5);
                                    }}>咨询一下</p>
                                </div>
                            </div>
                        </div>
                    </SlidePage>
                    <SlidePage className='page3' id='page3'>
                        <div className={this.state.active == 3 ? 'active' : ''}>
                            <h1 className='pubh1'>案例展示</h1>
                            <p className='pubp1'>CASE DISPLAY</p>
                            <p className='pubp2'>紧跟技术前沿， 保持创新实力定制研发， 为你的客户和团队完美设计。</p>
                            <div className='box'>
                                {this.state.caseList.map((item, index) => (
                                    <div className='item' key={String(index)} onClick={() => {
                                        window.open(`#/casedetail/${item.id}`)
                                    }}>
                                        <img alt='' src={item.img} className='img' />
                                        <div className='mask'>
                                            <img alt='' src={require('../imgs/search.png').default} className='search' />
                                        </div>
                                        <div className='wrap'>
                                            <div className='flex'>
                                                < h1>{item.name}</h1>
                                                <img alt='' src={require('../imgs/jt.png').default} />
                                            </div>
                                            <p className='desc'>{item.desc}</p>
                                            <div className='flexCenter'>
                                                {item.types.map((row, k) => (
                                                    <React.Fragment key={String(k)}>
                                                        {k < 3 && <p style={{ marginRight: 2 }} className='type'>{row}</p>}
                                                    </React.Fragment>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <p className='btnn' onClick={() => { window.open('#/case') }} > 了解更多</p>
                        </div>
                    </SlidePage>
                    <SlidePage className='page4' id='page4'>
                        <div className={this.state.active == 4 ? 'active' : ''}>
                            <h1 className='pubh1'>企业资质</h1>
                            <p className='pubp1'>ENTERPRISE QUALIFICATION</p>
                            <p className='pubp2' style={{ marginBottom: 40 }}>紧跟技术前沿，保持创新实力定制研发，为你的客户和团队完美设计。</p>
                            {this.state.zzList.length > 0 && <div className='box'>
                                <Carousel slidesPerRow={6} rows={2} ref={dom => this.zzSlideRef = dom}>
                                    {this.state.zzList.map((item, index) => (
                                        <div className='list' key={String(index)}>
                                            <img alt='' src={item.img} className='pic' />
                                        </div>
                                    ))}
                                </Carousel>
                                <img alt='' src={require('../imgs/next.png').default} className='prev jtt' onClick={() => {
                                    this.zzSlideRef.prev()
                                }} />
                                <img alt='' src={require('../imgs/next.png').default} className='next jtt' onClick={() => {
                                    this.zzSlideRef.next()
                                }} />
                            </div>}
                        </div>
                    </SlidePage>
                    <SlidePage className='page5' id='page5'>
                        <div className={this.state.active == 5 ? 'active' : ''}>
                            <h1 className='pubh1'>合作伙伴</h1>
                            <p className='pubp1'>COOPERATIVE PARTNER</p>
                            <p className='pubp2' style={{ marginBottom: '5%' }}>紧跟技术前沿， 保持创新实力定制研发， 为你的客户和团队完美设计。</p>
                            {this.state.parterList.length > 0 && <div className='box'>
                                <Carousel slidesPerRow={4} rows={2} ref={dom => this.slidePartRef = dom}>
                                    {this.state.parterList.map((item, index) => (
                                        <div className='list' key={String(index)}>
                                            <img alt='' src={item.logo} className='pic' />
                                            <div className='mask'>
                                                <img alt='' src={require('../imgs/sj.png').default} className='sj' />
                                                <p>{item.name}</p>
                                                <span>{item.desc}</span>
                                                <img alt='' src={require('../imgs/pp.png').default} className='pp' />
                                            </div>
                                        </div>
                                    ))}
                                </Carousel>
                                {this.state.parterList.length > 8 && <React.Fragment>
                                    <img alt='' src={require('../imgs/next.png').default} className='prev jtt' onClick={() => {
                                        this.slidePartRef.prev()
                                    }} />
                                    <img alt='' src={require('../imgs/next.png').default} className='next jtt' onClick={() => {
                                        this.slidePartRef.next()
                                    }} />
                                </React.Fragment>}
                            </div>}
                        </div>
                    </SlidePage>
                    <SlidePage className='page6' id='page6'>
                        <div className={this.state.active == 6 ? 'active' : ''}>
                            <h1 className='pubh1'>联系我们</h1>
                            <p className='pubp1'>CONTACT US</p>
                            <p className='pubp2' style={{ marginBottom: '5%' }}>紧跟技术前沿， 保持创新实力定制研发， 为你的客户和团队完美设计。</p>
                            <div className='flex'>
                                
                            </div>
                            <div className='flex addr' style={{ marginTop: 50 }}>
                                <img alt='' src={require('../imgs/phone.png').default} />
                                <p><a href='tel://19283868502' style={{color: 'white'}}>19283868502</a></p>
                                <img alt='' src={require('../imgs/addr.png').default} />
                                <p style={{ marginRight: 0 }}>河南省平顶山市汝州市风穴街道健康路市民之家1302室</p></div>
                            <p className='line'></p>
                            <div className='flex banq' style={{ marginTop: 0 }}>
                                <p style={{ marginRight: 24 }}>河南国算智云技术服务有限公司版权所有</p>
                                <p style={{ marginRight: 24 }}>Copyright© 2022 河南</p>
                                <p style={{ marginRight: 24 }}><a href='https://beian.miit.gov.cn/' style={{color: 'white'}}>豫ICP备2022011718号-1</a></p>
                                <p style={{ marginRight: 24 }}> <img alt='' src={require('../imgs/gongan.png').default} /><a href='https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=41048202000153' style={{color: 'white'}}>豫公网安备41048202000153号</a></p>
                            </div>
                        </div>
                    </SlidePage>
                </SlideContainer>
            </div>
        )
    }
}