import React from 'react';
import {
	Route,
	Switch,
	HashRouter as Router,
	Redirect,
} from 'react-router-dom';
import CacheRoute, { CacheSwitch } from 'react-router-cache-route'
import './App.css'
import './reset.css'

import ScrollToTop from './ScrollToTop';

import Home from './component/Home';  //首页
import Case from './component/Case';  //案例
import CaseDetail from './component/CaseDetail';  //案例详情

import Ajax from './class/Ajax';
global.Ajax = new Ajax();


export default class App extends React.Component {
	constructor(props) {
		super(props)
		this.state = {

		}
	}
	render() {
		return (
			<React.Fragment>
				<Router>
					<ScrollToTop>
						<CacheSwitch>
							<Route path="/home" component={Home} />
							<CacheRoute path="/case" component={Case} />
							<Route path="/casedetail/:id" component={CaseDetail} />

							{/* 默认访问 */}
							<Redirect to='/home' />
						</CacheSwitch>
					</ScrollToTop>
				</Router>
			</React.Fragment>
		)
	}
}
