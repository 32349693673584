import fetch from "isomorphic-fetch";

export default class Ajax {
    hhtc_request(url,data){
        var requrl=global.httpUrl+"/"+url;
        var init={
            method: 'POST',
            headers:{
                'Content-Type': 'application/json;',
                "Accept": "application/json"
            },
            body:JSON.stringify(data)
        }
        return  fetch(requrl,init).then(
             (response) => {
                return  response.json()
            }
        ).then(
            (data)=>{
                return data;
            }
        ).catch(
            (err)=>{
                console.log("执行一次网络请求:请求地址=》",requrl,"返回结果=>网络异常",err)
                var data={
                    code:0,
                    msg:'网络异常!'
                }
                return data;
            }
        )
    }
}